// import React, { useState,useEffect } from 'react';
// import { Helmet } from 'react-helmet-async';
// import img1 from '../assets/image/bengalical.png';
// import img2 from '../assets/image/hindi cal.png';
// import img3 from '../assets/image/kanadacal.png';
// import img4 from '../assets/image/ourcal.png';
// import img5 from '../assets/image/maratical.png';
// import img6 from '../assets/image/malayalam cal.png';
// import img7 from '../assets/image/Qibla.png';
// import img8 from '../assets/image/tamilcal.png';
// import img9 from '../assets/image/bhb.png';
// import img10 from '../assets/image/bhp.png';
// import '../assets/css/main.css'; // Import CSS file for styling
// import appsData from './appsdata';
// import LocalDeveloper from './islamic';
// import { Navigate, useNavigate } from 'react-router-dom';

// const apps = [
//   { id: 1, src: img1, alt: 'Bengali Calendar', description: 'Details about Bengali Calendar' },
//   { id: 2, src: img2, alt: 'Hindi Calendar', description: 'Details about Hindi Calendar' },
//   { id: 3, src: img3, alt: 'Kannada Calendar', description: 'Details about Kannada Calendar' },
//   { id: 4, src: img4, alt: 'Telugu Calendar', description: 'Details about Telugu Calendar' },
//   { id: 5, src: img5, alt: 'Marathi Calendar', description: 'Details about Marathi Calendar' },
//   { id: 6, src: img6, alt: 'Malayalam Calendar', description: 'Details about Malayalam Calendar' },
//   { id: 7, src: img7, alt: 'Qibla Finder', description: 'Details about Qibla Finder' },
//   { id: 8, src: img8, alt: 'Tamil Calendar', description: 'Details about Tamil Calendar' },
//   { id: 9, src: img9, alt: 'Bhagavad Gita (Bengali)', description: 'Details about Bhagavad Gita (Bengali)' },
//   { id: 10, src: img10, alt: 'Bhagavad Gita (Punjabi)', description: 'Details about Bhagavad Gita (Punjabi)' },
// ];

// const Portfolio = () => {
//   const [selectedApp, setSelectedApp] = useState(null);
//   const navigate = useNavigate()
//   const handleAppClick = (app) => {
//      navigate(`/app/id#${app.id}`)
    
//     setSelectedApp(app); 
//   };

//   useEffect(() => {
//     // Update the document title when the selected app changes
//     if (selectedApp) {
//       document.title = selectedApp.app;
//     }
//   }, [selectedApp]);

//   return (
//     <>
//     {selectedApp && (
//       <Helmet>
//         <title>{selectedApp.app}</title>
//         <meta name="description" content={selectedApp.description} />
//       </Helmet>
//     )}
//     <section id="portfolio" className="portfolio section">
//       <div className="container section-title aos-init aos-animate" data-aos="fade-up">
//         <h2>Portfolio</h2>
//       </div>

//       <div className="container">
//         <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
//           <ul className="portfolio-filters isotope-filters aos-init aos-animate text-center">
//             <h4 data-filter=".filter-app active"><strong>Our Apps</strong></h4>
//           </ul>

//           <div className="row gy-4 isotope-container aos-init aos-animate justify-content-center">
//             {apps.map((app) => (
//               <div
//                 key={app.id}
//                 className="col-lg-2 col-md-4 col-sm-6 portfolio-item isotope-item filter-app"
//                 onClick={() => handleAppClick(app)}
//                 style={{ cursor: 'pointer' }}
//               >
//                 <div className="portfolio-image-wrapper">
//                   <img src={app.src} className="img-style" alt={app.alt} />
//                   <div className="overlay">
//                     <div className="view-button">View</div>
//                   </div>
//                 </div>
//               </div>
//             ))}
//           </div>
//         </div>
//       </div>
//     </section>
//     </>
//   );
// };

// export default Portfolio;

import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import img1 from '../assets/image/bengalical.png';
import img2 from '../assets/image/hindi cal.png';
import img3 from '../assets/image/kanadacal.png';
import img4 from '../assets/image/ourcal.png';
import img5 from '../assets/image/maratical.png';
import img6 from '../assets/image/malayalam cal.png';
import img7 from '../assets/image/Qibla.png';
import img8 from '../assets/image/tamilcal.png';
import img9 from '../assets/image/bhb.png';
import img10 from '../assets/image/bhp.png';
import '../assets/css/main.css';

const apps = [
  { id: 1, src: img1, alt: 'Bengali Calendar', description: 'Details about Bengali Calendar' },
  { id: 2, src: img2, alt: 'Hindi Calendar', description: 'Details about Hindi Calendar' },
  { id: 3, src: img3, alt: 'Kannada Calendar', description: 'Details about Kannada Calendar' },
  { id: 4, src: img4, alt: 'Telugu Calendar', description: 'Details about Telugu Calendar' },
  { id: 5, src: img5, alt: 'Marathi Calendar', description: 'Details about Marathi Calendar' },
  { id: 6, src: img6, alt: 'Malayalam Calendar', description: 'Details about Malayalam Calendar' },
  { id: 7, src: img7, alt: 'Qibla Finder', description: 'Details about Qibla Finder' },
  { id: 8, src: img8, alt: 'Tamil Calendar', description: 'Details about Tamil Calendar' },
  { id: 9, src: img9, alt: 'Bhagavad Gita (Bengali)', description: 'Details about Bhagavad Gita (Bengali)' },
  { id: 10, src: img10, alt: 'Bhagavad Gita (Punjabi)', description: 'Details about Bhagavad Gita (Punjabi)' },
];

const Portfolio = () => {
  const [selectedApp, setSelectedApp] = useState(null);
  const navigate = useNavigate();

  const handleAppClick = (app) => {
    navigate(`/app/id#${app.id}`);
    setSelectedApp(app);
  };

  return (
    <>
     

      <section id="portfolio" className="portfolio section">
        <div className="container section-title aos-init aos-animate" data-aos="fade-up">
          <h2>Portfolio</h2>
        </div>

        <div className="container">
          <div className="isotope-layout" data-default-filter="*" data-layout="masonry" data-sort="original-order">
            <ul className="portfolio-filters isotope-filters aos-init aos-animate text-center">
              <h4 data-filter=".filter-app active">
                <strong>Our Apps</strong>
              </h4>
            </ul>

            <div className="row gy-4 isotope-container aos-init aos-animate justify-content-center">
              {apps.map((app) => (
                <div
                  key={app.id}
                  className="col-lg-2 col-md-4 col-sm-6 portfolio-item isotope-item filter-app"
                  onClick={() => handleAppClick(app)}
                  style={{ cursor: 'pointer' }}
                >
                  <div className="portfolio-image-wrapper">
                    <img src={app.src} className="img-style" alt={app.alt} />
                    <div className="overlay">
                      <div className="view-button">View</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Portfolio;




