import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const AppRouteHandler = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const path = location.pathname;
    const hash = location.hash;
    const ID = location.hash ? location.hash.substring(1) : null;
    const pathParts = path.split("/");

    // Check if the path contains "/app/id" and extract the last part (ID)
    const id = pathParts[pathParts.length - 1];
    const isValidId = !isNaN(parseInt(ID)) && parseInt(ID) > 0; // Check if it's a valid numeric ID

    // If the ID is not valid, remove "/app/id" and check hash
    if (path.startsWith("/app/id") && !isValidId) {
       
      // Navigate to the root or fallback if the ID is not numeric
      navigate(`/${hash}` || "/"); // Redirect to the hash (if available) or fallback to "/"
    } else if (isValidId) {
     
    }
  }, [location, navigate]);


  return null;
};

export default AppRouteHandler;
