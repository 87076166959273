
// import React from 'react';
// import Header from './header';
// import HeroSection from './slider';
// import About from './about';
// import Portfolio from './portfolio';
// import Contact from './contact';
// import Footer from './footer';
// import Active from './active';
// import OurSpecialization from './tech stack';


// function Index() {
//   return (
//     <>
//     <Header/>
//     <HeroSection/>
//     <About/>
    
//     <Portfolio/>
//     <Active/>
//     <OurSpecialization/>
//     <Contact/>
//     <Footer/>
  
//     </>
//   );
// }

// export default Index;
import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import Header from './header';
import HeroSection from './slider';
import About from './about';
import Portfolio from './portfolio';
import Contact from './contact';
import Footer from './footer';
import Active from './active';
import OurSpecialization from './tech stack';
import Seo from './Seo';
import { useLocation } from 'react-router-dom';

function Index() {
  const location = useLocation();
  return (
    <HelmetProvider>
      <>
      <Seo page={location.hash} />
        <Header />
        
        {/* Home section SEO */}
      
        <HeroSection />
        
        {/* About section SEO */}
        {/* <Seo page="about" /> */}
        <About />
        
        {/* Portfolio section SEO */}
        {/* <Seo page="portfolio" /> */}
        <Portfolio />
        
        {/* Active section SEO */}
        {/* <Seo page="active" /> */}
        <Active />
        
        {/* Our Specialization section SEO */}
        {/* <Seo page="techStack" /> */}
        <OurSpecialization />
        
        {/* Contact section SEO */}
        {/* <Seo page="contact" /> */}
        <Contact />
        
        <Footer />
      </>
    </HelmetProvider>
  );
}

export default Index;


