import React from 'react';
import '../assets/css/main.css'


const About = () => {
  return (
 <>
    <section id="about" className="about section" >
      <div className="container section-title aos-init aos-animate" data-aos="fade-up">
        <h2>About us</h2>
        {/* <p>Who we are</p> */}
      </div>

      <div className="container">
        <div className="row gy-4">
          <div className="col-lg-6 content aos-init aos-animate" data-aos="fade-up" data-aos-delay="100">
            <p>
            Our Story:
            At Local Developer Apps, we're all about turning your visions into reality. We believe that great apps come from great collaboration. That's why we take the time to get to know you and your needs, ensuring we create something that truly fits.
            </p>
            <ul>
              <li><i className="bi bi-check2-circle"></i> <span>Innovation: We love coming up with new ideas and using the latest technology to make them happen.</span></li>
              <li><i className="bi bi-check2-circle"></i> <span>Quality: We’re committed to building apps that are reliable, secure, and delightful to use.</span></li>
              <li><i className="bi bi-check2-circle"></i> <span>Customer-Centric: You’re at the heart of everything we do. We listen, we adapt, and we deliver what you need.</span></li>
              <li><i className="bi bi-check2-circle"></i> <span>Passion: We’re passionate about what we do and are always looking for ways to improve and innovate.</span></li>
            
            </ul>
          </div>

          <div className="col-lg-6 aos-init aos-animate" data-aos="fade-up" data-aos-delay="200">
            <ul>
              <h5>Why Choose Us </h5>
            <li><i className="bi bi-check2-circle"></i> <span>Experienced Team: Our team comprises talented developers, creative designers, and dedicated project managers who bring their best to every project.</span></li>
              <li><i className="bi bi-check2-circle"></i> <span>Proven Track Record: With over 5000 users and thousands of downloads, a growing community trusts and enjoys our apps.</span></li>
              <li><i className="bi bi-check2-circle"></i> <span>Full-Service Solutions: Whether it’s developing a custom app, designing an intuitive user interface, or providing ongoing support, we’ve got you covered.</span></li>
              <li><i className="bi bi-check2-circle"></i> <span>Personal Touch: We build strong relationships with our clients, working closely with you to ensure we deliver exactly what you need.</span></li>
            </ul>
            <h4>Join Our community</h4>
            <p>We’re excited to invite you to be a part of the Local Developer Apps family. Whether you're a business looking to innovate or someone with a great app idea, we're here to help you succeed.
            Thank you for considering Local Developer Apps as your partner in mobile app development. Let’s create something amazing together!</p>
            {/* <a href="#" className="read-more">
              <span>Read More</span><i className="bi bi-arrow-right"></i>
            </a> */}
          </div>
        </div>
      </div>
    </section>
    </>
  );
};

export default About;
