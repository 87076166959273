import React, { useState, useEffect } from 'react';
import '../assets/css/main.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import img from '../assets/image/locallogo.png';
import { Offcanvas } from 'bootstrap';
import { useLocation } from 'react-router-dom';

const Header = () => {
  const [menuActive, setMenuActive] = useState(false);
  const [activeSection, setActiveSection] = useState('#home');
  const location = useLocation();
  const [currentHeading, setCurrentHeading] = useState('Home'); // Default heading

  useEffect(() =>{
   if(location.hash){
    handleLinkClick(location.hash)
   }
  },[location])
  useEffect(() => {

    // Get all sections with IDs
    const sections = document.querySelectorAll('section[id]');
    const handleScroll = () => {
      const sections = document.querySelectorAll('section[id]');
      let currentSection = '#home'; // Default section
      let currentSectionHeading = 'Home'; // Default heading
    
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.offsetHeight;
        const sectionBottom = sectionTop + sectionHeight;
    
        // Adjust logic to account for the top of the page
        if (window.scrollY >= sectionTop - window.innerHeight / 2 && window.scrollY < sectionBottom) {
          currentSection = `#${section.id}`;
          const heading = section.querySelector('h1, h2, h3, h4, h5, h6');
          currentSectionHeading = heading ? heading.textContent : '';
        }
      });
    
      // Update state
      setActiveSection(currentSection);
      setCurrentHeading(currentSectionHeading);
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const offcanvas = document.getElementById('offcanvasMenu');
    if (offcanvas) {
      const bsOffcanvas = new Offcanvas(offcanvas);
      return () => bsOffcanvas.dispose();
    }
  }, []);

  const handleToggle = () => {
    setMenuActive(!menuActive);
  };

  const handleLinkClick = (section) => {
    setActiveSection(section);
    setMenuActive(false);
    const offcanvas = document.getElementById('offcanvasMenu');
    if (offcanvas) {
      const bsOffcanvas = Offcanvas.getInstance(offcanvas);
      if (bsOffcanvas) {
        bsOffcanvas.hide();
      }
    }
    // Smooth scroll to the section
    const element = document.querySelector(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <div>
      <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <img src={img} className="sitename" alt="Local Logo" />
          </a>

          <nav id="navmenu" className={`navmenu ${menuActive ? 'active' : ''}`}>
            <ul>
              <li>
                <a
                  href="#home"
                  className={activeSection === '#home' ? 'active' : ''}
                  onClick={() => handleLinkClick('#home')}
                >
                  Home
                </a>
              </li>
              <li>
                <a
                  href="#about"
                  className={activeSection === '#about' ? 'active' : ''}
                  onClick={() => handleLinkClick('#about')}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  href="#portfolio"
                  className={activeSection === '#portfolio' ? 'active' : ''}
                  onClick={() => handleLinkClick('#portfolio')}
                >
                  Portfolio
                </a>
              </li>
              <li>
                <a
                  href="#techstack"
                  className={activeSection === '#techstack' ? 'active' : ''}
                  onClick={() => handleLinkClick('#techstack')}
                >
                  Tech stack
                </a>
              </li>
              <li>
                <a
                  href="#contact"
                  className={activeSection === '#contact' ? 'active' : ''}
                  onClick={() => handleLinkClick('#contact')}
                >
                  Contact
                </a>
              </li>
            </ul>
          </nav>

          <i
            className={`mobile-nav-toggle bi bi-list`}
            onClick={handleToggle}
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasMenu"
            aria-controls="offcanvasMenu"
          ></i>
        </div>
      </header>

      <div
        className="offcanvas offcanvas-end"
        tabIndex="-1"
        id="offcanvasMenu"
        aria-labelledby="offcanvasLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header">
          <h5 className="offcanvas-title" id="offcanvasLabel">Menu</h5>
          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div className="offcanvas-body">
          <ul>
            <li>
              <a
                href="#home"
                className={activeSection === '#home' ? 'active' : ''}
                onClick={() => handleLinkClick('#home')}
              >
                Home
              </a>
            </li>
            <li>
              <a
                href="#about"
                className={activeSection === '#about' ? 'active' : ''}
                onClick={() => handleLinkClick('#about')}
              >
                About
              </a>
            </li>
            <li>
              <a
                href="#portfolio"
                className={activeSection === '#portfolio' ? 'active' : ''}
                onClick={() => handleLinkClick('#portfolio')}
              >
                Portfolio
              </a>
            </li>
            <li>
              <a
                href="#techstack"
                className={activeSection === '#techstack' ? 'active' : ''}
                onClick={() => handleLinkClick('#techstack')}
              >
                Tech stack
              </a>
            </li>
            <li>
              <a
                href="#contact"
                className={activeSection === '#contact' ? 'active' : ''}
                onClick={() => handleLinkClick('#contact')}
              >
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Header;

