// appData.js
const appsData = [
    {
      id: 7,
      title: "Islamic Apps: Quran and Qibla",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "5",
      appIcon:  require("../assets/image/Qibla.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.urdu.calendar&hl=en-IN",
      iosLink: " https://apps.apple.com/in/app/id1448475082",
      bannerImage:  require("../assets/image/Islamic Apps feature image.webp"),
      description: "Islamic Apps: Quran and Qibla",
      features: [
        {
          details:["Islamic Calendar App: Muslim Prayer Times, Quran and Qibla. Stay connected to your faith with this all-in-one Islamic app. This Muslim guide provides Quran recitation, Qibla direction, nearby mosques, Islamic calendar 2025 in urdu, Ramadan timings, Zakat calculator, 99 Names of Allah & Prophet (SAW), daily duas, Tasbih counter, Hajj & Umrah guide, 6 Kalimas, Surah Yaseen, Hijri converter, Islamic names, and more. Perfect for every Muslim who speaks urdu."]
        },
        {
          title: "Muslim Athan, Al Quran and Qibla Key Features:",
          details: [
            "Hijri Calendar (Islamic Calendar): Get a detailed overview of the Islamic Hijri calendar in Urdu. This app provides Islamic months and dates so you can plan ahead for all important events, including Ramadan, Eid-ul-Fitr, and Eid-ul-Adha. View the current Islamic year and access key historical Islamic dates.",
            "2025 Prayer Times: Accurate daily prayer times based on your location (Fajr, Dhuhr, Asr, Maghrib, Isha). Stay up to date with daily prayers and never miss a Salah again. The app calculates prayer times using various methods including Umm al-Qura, ISNA, and more.",
            "Ramadan Calendar: Stay prepared for the holy month of Ramadan with detailed Suhoor and Iftar times, fasting schedules, and prayer times during Ramadan.",
            " Islamic Events: Get timely reminders for Islamic events such as Lailat-ul-Qadr, Ashura, Hajj, and more. Plan your religious observances with precision.",
            "Urdu Calendar: The app is in Urdu language for ease of use by Urdu-speaking Muslims. Navigate easily and access all features in your native language.",
            "Moon Phases: View the Islamic lunar phases, important for the sighting of the moon during key events like Ramadan and Eid.",
            "Special Islamic Days: Be reminded of significant Islamic days such as Isra and Mi'raj, 12th Rabi-ul-Awwal (Mawlid), Islamic New Year, and more.",
            "Qibla Direction: Find the Qibla accurately from anywhere in the world.",
            " Islamic 2025 Year View: Overview of the entire Islamic year with holidays and events.",
            "Daily Hadith & Quranic Ayahs: Get daily inspiration with a new Hadith or Quran verse.",
         
          ],
        },
        {
          title: "Why Choose Muslim Athan, Al Quran & Qibla?",
          details: [
            "Accurate Information: Our app is updated with the latest data to provide you with precise Hijri dates and prayer times. Never worry about incorrect dates for key religious events, Adhan notifications",
            " Simple and Intuitive: Designed for ease of use, especially for Urdu speakers. Whether you need to check the Hijri date or find the next prayer time, it’s all just a tap away.",
            "Lightweight & Offline Access: No need for continuous internet access. The app works offline and provides all the information you need at any time.",
            "Daily Islamic Reminders & Duas: Receive daily Islamic reminders and duas to help you stay spiritually connected"
          ],
        },
      ],
      sliderImages: [
        require("../assets/image/Q-1.webp"),
        // require("../assets/image/Q-2.webp"),
        require("../assets/image/Q-3.webp"),
        require("../assets/image/Q-4.webp"),
        require("../assets/image/Q-5.webp"),
        require("../assets/image/Q-6.webp"),
        require("../assets/image/Q-7.webp"),
        require("../assets/image/Q-8.webp"),
    ], // Dynamically importing 
    },
    {
      id: 8,
      title: "Tamil Calendar 2025",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.3+",
      appIcon:  require("../assets/image/tamilcal.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.tamil.calendar&hl=en-IN",
      iosLink: " https://apps.apple.com/in/app/tamil-calendar-panchangam/id1448473576",
      bannerImage:  require("../assets/image/tamilf.png"),
      description: "Tamil Calendar 2025",
      features: [
        {
          details:["Tamil Calendar 2025 Panchangam - தமிழ் காலண்டர் 2025 பஞ்சாங்கம் Stay connected to Tamil traditions and plan your year effortlessly with the Tamil Calendar 2025 Panchangam app! Designed for Tamil-speaking users worldwide, this 2025 Calendar app is packed with features to keep you updated on festivals, auspicious timings, Panchangam, and daily astrology. Whether you’re planning a big event or simply checking the day’s horoscope, this app has you covered."]
        },
        {
          title: "Why Choose Tamil Calendar 2025 (தமிழ் காலண்டர் 2025)?",
          details: [
            "Complete Tamil Calendar Panchangam - முழுமையான தமிழ் நாட்காட்டி பஞ்சாங்கம் Access all Tamil months, festivals, and traditional events in one place. Stay informed about tithis, nakshatras, yogas, and Tamil festivals like Pongal, Puthandu, and Karthigai Deepam.",
            "Daily Panchangam - தினசரி பஞ்சாங்கம் Check daily Panchangam details, including tithi, nakshatra, yogam, sunrise and sunset timings, rahu kalam, and yama gandam.",
            "Festivals and Holidays - பண்டிகை மற்றும் விடுமுறை நாட்கள் Get a full list of Tamil festivals, religious holidays, and multifaith celebrations for 2025 to plan your year in advance.",
            " Auspicious Muhurtham - சுப முகூர்த்தம் Find the best times for weddings, housewarmings, or starting new ventures based on Tamil astrology.",
            " Daily Rasi Palan (Horoscope) - தினசரி ராசி பலன் Start your day on the right note with personalized astrological insights, delivered daily and weekly.",
            " Offline Access - இணையதள இல்லாமலே பயன்படுத்துங்கள் Access all features without internet, so you’re never without your Tamil calendar.",
            "Tamil Panchangam Special Features - தமிழ் பஞ்சாங்கம் சிறப்பு அம்சங்கள்",
            " Nalla Neram and Gowri Panchangam - நல்ல நேரம் மற்றும் கௌரி பஞ்சாங்கம் Discover the most auspicious times to make important decisions or start new tasks.",
            " Marriage Matching (Thirumana Porutham) - திருமண பொருத்தம் Quickly check star compatibility for marriage proposals.",
            "2025 Spiritual Dates - ஆன்மிக நாட்கள் Track special lunar days like Pournami, Amavasai, and Ekadashi for prayers and rituals.",
            "Vrat and Pooja Details - விரத மற்றும் பூஜை விவரங்கள் Access complete guidelines for fasting days and puja rituals to maintain your spiritual practices.",
            " 2025 Holiday / Festival Reminders - பண்டிகை நினைவூட்டல் Get timely reminders about Tamil festivals, religious holidays, and panchang special events.",
            "Traditional Look - பாரம்பரிய தோற்றம் Enjoy a design that mirrors traditional Tamil calendars with a modern touch for easy navigation.",
            "தமிழ் காலண்டர் 2025 உங்கள் பாரம்பரியத்தை பின்பற்றவும், உங்கள் ஆண்டு முழுவதையும் சிறப்பாக அமைக்கவும் உதவும். இந்த செயலி திதி, நக்ஷத்திரம், சுப நேரம், மற்றும் முக்கிய பண்டிகைகள் பற்றிய அனைத்தையும் ஒரு இடத்தில் வழங்குகிறது. நிகழ்வுகளை திட்டமிடுதல், பூஜை நேரம் அறிதல் அல்லது ராசி பலனை பார்வையிடுதல் போன்றவை இப்போது மிகவும் எளிது!",
            "Plan Your Year with 2025 Tamil Calendar - தமிழ் காலண்டர் 2025 பஞ்சாங்கம் Make 2025 meaningful and memorable with the Tamil Calendar 2025 app! Whether it’s preparing for Pongal, finding the right time for a housewarming, or checking daily astrological updates, this app keeps you organized and rooted in tradition.",
          ],
        },
        {
          title: "Why Choose Muslim Athan, Al Quran & Qibla?",
          details: [
            "Accurate Information: Our app is updated with the latest data to provide you with precise Hijri dates and prayer times. Never worry about incorrect dates for key religious events, Adhan notifications",
            " Simple and Intuitive: Designed for ease of use, especially for Urdu speakers. Whether you need to check the Hijri date or find the next prayer time, it’s all just a tap away.",
            "Lightweight & Offline Access: No need for continuous internet access. The app works offline and provides all the information you need at any time.",
            "Daily Islamic Reminders & Duas: Receive daily Islamic reminders and duas to help you stay spiritually connected"
          ],
        },
      ],
      sliderImages: [
        require("../assets/image/ta-1.webp"),
        require("../assets/image/ta-2.webp"),
        require("../assets/image/ta-3.webp"),
        require("../assets/image/ta-4.webp"),
        require("../assets/image/ta-5.webp"),
        require("../assets/image/ta-6.webp"),
        require("../assets/image/ta-7.webp"),
       
    ], // Dynamically importing 
    },
    {
      id: 9,
      title: "Bhagavad Gita Bengali",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.3+",
      appIcon:  require("../assets/image/bhb.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=bengali.bhagavadgita&hl=en-IN",
      iosLink: " https://apps.apple.com/in/app/bhagavad-gita-bengali/id6520385956",
      bannerImage:  require("../assets/image/bengalif.png"),
      description: "Bhagavad Gita Bengali",
      features: [
        {
          details:["Bhagavad Gita in Bengali (Bangla) is your ultimate companion to explore the divine teachings of the Bhagavad Gita, also known as Shreemad Bhagwat Geeta or Bhagavad Geeta, presented in the poetic language of Bangla. This app is designed for Bengali readers seeking to understand the sacred scripture in their native tongue. Whether you're a lifelong devotee or new to the Geeta teachings, this app makes the wisdom of the Bengali scriptures easily accessible."]
        },
        {
          title: "Key Features:",
          details: [
            "Full Bhagavad Gita in Bangla (Bengali): Shrimad Bhagavad Gita is now available in the rich and poetic language of Bangla. Immerse yourself in the sacred teachings and teachings of Lord Krishna with a translation that is both authentic and easy to understand. Whether you seek to deepen your knowledge of the Bhagwat Geeta or simply want to explore Geeta in your native language, our app is designed for all users.",
            "Geeta in Bengali with Audio: The app brings the divine words of Lord Krishna to life in Bangla. Each verse is available in both text and audio formats, allowing you to read or listen to the verses wherever you are.",
            "Shloka of the Day: Receive a daily shloka from the Bhagavad Gita or Shreemad Bhagwat Geeta in Bengali, designed to inspire and guide you throughout your day. This feature allows you to keep the teachings of Geeta close to you, enabling daily reflection and learning.",
            " Bookmark Your Favorite Shlokas: Save your most cherished Bhagavad Gita verses, shlokas, and Geeta teachings for easy access. You can quickly revisit them whenever you need spiritual guidance, and even share your favorite shlokas with friends and family to spread the wisdom of the sacred texts.",
            " Search and Explore: Quickly find specific Bhagavad Gita verses, shlokas, or teachings with the built-in search feature. You can easily locate any Bhagwat Geeta or Geeta verse in Bengali and study its meaning. The app’s search functionality lets you focus on specific chapters or themes from the sacred texts that resonate with you.",
            " Offline Access: No internet? No problem! Bhagavad Gita in Bengali allows you to access the teachings of the Bhagwat Geeta without needing a constant connection to the web. Download your favorite chapters, verses, and shlokas and access them anytime, anywhere.",
            "Share the Geeta Wisdom: Share your favorite shlokas and Bhagavad Gita teachings with others directly from the app. Spread the divine wisdom of Geeta and Bhagwat Geeta among your friends, family, and social networks, and encourage others to discover the teachings of the sacred texts in Bengali.",
           
          ],
        },
        {
          title: "Why Choose Bhagavad Gita ?",
          details: [
            "The Complete Shrimad Bhagavad Gita in Bangla: Get access to the full Bhagavad Gita text, including all its shlokas, teachings, and translations in Bangla. Whether you refer to it as Bhagavad Geeta or Shree Krishna’s Geeta, this app brings all the chapters to your mobile device.",
            " Designed for Bengali Readers: Our app is specifically designed for Bangla speakers, with Bengali scriptures that cater to the language and cultural preferences of Bengali readers. The interface is easy to navigate, making it simple to explore Geeta teachings in your native language.",
            "Perfect for All Users: Whether you're a lifelong devotee of the Bhagwat Geeta or a newcomer, this app provides a rich, engaging experience for anyone interested in learning about the teachings of Bhagavad Gita.",
            "Continuous Updates: The app is regularly updated to ensure that you have the best possible experience. We continually add new features and content to make your spiritual journey even more fulfilling."
          ],
        },
        {
        details:[
          "Bhagavad Gita Bengali in Bangla is not just an app; it’s your guide to the profound spiritual wisdom of the Bhagavad Gita. With daily reflections, shloka sharing, and full access to the sacred teachings of Geeta, you can stay connected with the divine and make spiritual growth an integral part of your life."
        ]}
      ],
      sliderImages: [
        require("../assets/image/b-1.webp"),
        require("../assets/image/b-2.webp"),
        require("../assets/image/b-3.webp"),
        require("../assets/image/b-4.webp"),
        require("../assets/image/b-5.webp"),
        require("../assets/image/b-6.webp"),
        require("../assets/image/b-7.webp"),
        require("../assets/image/b-8.webp"),
       
    ], // Dynamically importing 
    },
    {
      id: 10,
      title: "Bhagavad Gita Punjabi",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "5",
      appIcon:  require("../assets/image/bhp.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=punjabi.bhagavadgita&hl=en-IN",
      iosLink: " https://apps.apple.com/in/app/bhagavad-gita-punjabi/id6504911009",
      bannerImage:  require("../assets/image/panjabif.png"),
      description: "Bhagavad Gita Punjabi",
      features: [
        {
          details:["Experience the Bhagavad Gita's timeless wisdom in Punjabi! This revered scripture, part of the Mahabharata, offers profound insights into life, duty, and self-realisation. Our Punjabi translation makes these teachings accessible to Punjabi-speaking readers."]
        },
        {
       
          details: [
            "Bhagavad Gita Punjabi comprises 18 chapters and 700 verses, capturing a dialogue between Prince Arjuna and Lord Krishna on the Kurukshetra battlefield. Krishna imparts divine wisdom, revealing principles of Karma Yoga (selfless action), Bhakti Yoga (devotion), and Jnana Yoga (knowledge) to help Arjuna navigate his moral dilemma.",
            "This edition brings the ancient text to life with detailed explanations for each verse, making it easier to understand and apply the teachings to modern life. It's designed for both devout practitioners and those seeking spiritual guidance.",
            "Discover how to achieve inner peace, clarity, and a sense of duty through the Gita's teachings. Learn to overcome doubts and fears, embracing a life of righteousness and spiritual fulfilment. The Gita's universal lessons provide wisdom transcending time and culture.",
            "Our Punjabi translation serves as a spiritual guide, helping you connect with Krishna's divine wisdom. It's more than a book—it's a journey to self-discovery and enlightenment. Let it illuminate your path with its timeless truths.",
            "Embark on a transformative journey with the Bhagavad Gita in Punjabi. Experience the divine dialogue that has inspired millions, guiding you to a life of harmony, purpose, and spiritual awakening. Read, reflect, and let the Gita's wisdom transform your life.",
          
           
          ],
        },
        {
          title: "Disclaimer:",
          details: [
            "The Bhagavad Gita app is intended for educational and informational purposes only. The content, including text, images, translations, and interpretations, is derived from various sources to provide access to the teachings of the Bhagavad Gita. Users should consult other sources and professionals for comprehensive understanding and personal guidance. The app developers are not responsible for any inaccuracies or actions taken based on the app content.",
            
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/p-1.webp"),
        require("../assets/image/p-2.webp"),
        require("../assets/image/p-3.webp"),
        require("../assets/image/p-4.webp"),
        require("../assets/image/p-5.webp"),
        require("../assets/image/p-6.webp"),
        require("../assets/image/p-7.webp"),
       
       
    ], // Dynamically importing 
    },
    {
      id: 4,
      title: "Telugu Calendar 2025 తెలుగు",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.8+",
      appIcon:  require("../assets/image/ourcal.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.telugu.calendar&hl=en-IN",
   
      bannerImage:  require("../assets/image/teluguf.png"),
      description: "Telugu Calendar 2025 తెలుగు",
      features: [
        {
          details:["Telugu Calendar 2025 తెలుగు క్యాలెండర్‌ పంచాంగం is your ultimate companion for staying in touch with Telugu culture, traditions, and spiritual practices. Designed specifically for Telugu-speaking users, this app is the most comprehensive and easy-to-use Telugu calendar, packed with features to make your life more organized and meaningful. Whether you’re planning festivals, finding auspicious muhurthalu, or checking daily Panchangam details, Telugu Calendar 2025 తెలుగు పంచాంగం has everything you need in one place."]
        },
        {
       
          details: [
            "Daily Panchangam Details: Get accurate Panchangam information based on Telugu(తెలుగు) traditions, including tithi, nakshatra, karana, and yoga. Whether you’re starting a new venture or planning a puja, the app provides reliable data to help you choose the right time.",
            "2025 Calendar Festivals: Celebrate all major Telugu festivals with the app’s comprehensive list of festival dates. From Sankranti and Ugadi to Deepavali and Dasara, you’ll have all the details you need to plan your celebrations.",
            "Auspicious Muhurthalu: Looking for the perfect time for your next big event? The app provides detailed muhurthalu for weddings, housewarming ceremonies, naming ceremonies, and other important occasions. Trust Telugu Calendar 2025 to guide you with auspicious timings based on traditional practices.",
            "Daily Astrology – Rasi Phalalu: Start your day on the right note with personalized Rasi Phalalu (daily horoscope) in Telugu. Understand your zodiac predictions and plan your day accordingly with insights from the Telugu Panchangam.",
            "Ekadasi, Pournami, and Amavasya Dates: Keep track of spiritually important days like Ekadasi, Pournami, and Amavasya. Perform your rituals and prayers on the right days with guidance from the Telugu calendar.",
            "Offline Access: No internet? No problem! Telugu Calendar 2025 works offline, giving you uninterrupted access to all features, including Panchangam, festivals, and muhurthalu.",
            "Bookmark and Share Shlokas and Events: Bookmark your favorite shlokas, festivals, or important dates and share them directly with friends and family. Spread the joy of Telugu(తెలుగు) culture with just a tap.",
          
           
          ],
        },
        {
          title: "Why Choose Telugu Calendar 2025 Panchangam?",
          details: [
            "Tailored for Telugu-Speaking Users: The app is designed exclusively for Telugu(తెలుగు) families and individuals, making it easy to navigate and use in your preferred language.",
            "Authentic and Accurate: All dates, muhurthalu, and Panchangam details are based on authentic Telugu traditions, ensuring accuracy and reliability.",
            "All-in-One Solution: Whether it’s planning a wedding, finding festival dates, or checking Rasi Phalalu, the app provides all the tools you need in one place.",
            "Regular Updates: Stay up-to-date with regular updates to enhance your experience and include the latest Telugu(తెలుగు) calendar information.",
            "Perfect for All Generations: Telugu Calendar 2025 caters to every generation, from elders looking for traditional dates to young professionals planning events.",
             
          ],
        },
        {
          title: "Plan Your Year with Telugu Calendar 2025",
          details: [
            "Make 2025 a year to remember with Telugu Calendar 2025! Whether you’re preparing for festivals, finding the right time for puja's, or simply staying connected to Telugu traditions.",
            "Stay organized, celebrate your culture, and make every day meaningful with Telugu Calendar 2025. The app is your go-to guide for Telugu(తెలుగు) Panchangam, festivals, and spiritual events.",
            "Download Telugu Calendar 2025 today and never miss an important Telugu date, muhurtham, or festival. With this app, you’ll always stay connected to your roots and traditions.",

             
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/te-1.webp"),
        require("../assets/image/te-2.webp"),
        require("../assets/image/te-3.webp"),
        require("../assets/image/te-4.webp"),
        require("../assets/image/te-5.webp"),
        require("../assets/image/te-6.webp"),
        require("../assets/image/te-7.webp"),
       
       
    ], // Dynamically importing 
    },
    {
      id: 6,
      title: "Malayalam Calendar 2025",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.7+",
      appIcon:  require("../assets/image/malayalam cal.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.malayalam.calendar&hl=en-IN",
      iosLink: "https://apps.apple.com/in/app/malayalam-calendar-2025/id1448475336",
      bannerImage:  require("../assets/image/malayalamf.png"),
      description: "Malayalam Calendar 2025",
      features: [
        {
          details:["Step into 2025 with the most user-friendly Malayalam Calendar 2025 Panchangam app, designed for Malayalam-speaking people worldwide. Whether it’s planning a festival, checking auspicious timings, or following daily Panchangam details, this app is your trusted companion for all things cultural and traditional."]
        },
        {
          title: "Malayalam Calendar 2025 Key Features:",
          details: [
            "Complete Malayalam Calendar 2025 - മലയാള കലണ്ടർ 2025:",
            " View the entire year at a glance with both Malayalam and Gregorian months.",
            "Easily navigate through Tithis, Nakshatras, Yoga, Karana, and important dates.",
            "Includes sunrise, sunset, moonrise, and moonset timings for every day.",
            "Festivals and Holidays:",
            "2025ലെ ഹിന്ദു, ക്രൈസ്തവ, മുസ്ലിം ഉത്സവങ്ങളും പൊതു ദിനങ്ങളും",
            "Find an updated list of all major festivals and holidays, including Vishu, Onam, Christmas, and Ramadan.",
            " Perfectly curated to help you plan your celebrations and vacations.",
            " Daily Panchangam and Rashi Phalam:",
            "Access precise Tithi, Nakshatra, Yoga, Karana, and Paksha details for each day.",
            "Personalized daily and weekly horoscopes (Rashi Phalam) to help you stay prepared.",
            "Shubh Muhurat and Auspicious Timings:",
            "Plan special occasions like weddings, housewarming, and property purchases with accurate Muhurat timings.",
            " Offline Access: Use the app anytime, anywhere, even without an internet connection.",
            "മലയാളം കലണ്ടർ 2025 നിങ്ങളുടെ ദൈനംദിന ജീവിതത്തിന്റെ അഭിഭാഷകമാണ്. പ്രാധാന്യമുള്ള തിയതികൾ, പഞ്ചാംഗ വിവരങ്ങൾ, ആഘോഷങ്ങൾ എന്നിവയെക്കുറിച്ചുള്ള അറിയിപ്പ് നേടുക. നിങ്ങളുടെ ഉത്സവങ്ങളും പുണ്യദിനങ്ങളും പ്ലാൻ ചെയ്യാൻ ഇത് മികച്ച സഹായി. എല്ലാ തലമുറക്കാർക്കും അനുയോജ്യമായ രീതിയിൽ തയ്യാറാക്കിയിരിക്കുന്ന ഈ അപ്ലിക്കേഷൻ നിങ്ങളുടെ മലയാളം സംസ്‌കാരത്തിലേക്ക് ഒരു പടവാളംകൂടിയാണ്.",
           
          ],
        },
        {
          title: "Why Malayalam Calendar 2025?",
          details: [
            "Made for Malayalam Culture: Specifically tailored for the Malayalam-speaking community.",
            " Comprehensive Details: Covers everything from daily Panchang to Shubh Muhurat and festivals.",
            "Accurate and Reliable: Trusted information for all your cultural and astrological needs.",
            " Easy to Use: Designed for users of all ages with a simple and clean layout.",
            "Works Offline: Stay connected to the calendar and Panchang even without internet access.",
             
          ],
        },
        {
          
          details: [
            "Plan Your Year with Malayalam Calendar 2025 From observing fasts and festivals to finding the perfect Muhurat, Malayalam Calendar 2025 is your reliable companion. It’s not just a calendar but a guide to making 2025 your most well-organized and culturally enriched year.",

             
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/M-1.webp"),
        require("../assets/image/M-2.webp"),
        require("../assets/image/M-3.webp"),
        require("../assets/image/M-4.webp"),
        require("../assets/image/M-5.webp"),
        require("../assets/image/M-6.webp"),
        require("../assets/image/M-7.webp"),
       
       
    ], // Dynamically importing 
    },
    {
      id: 5,
      title: "Marathi Calendar 2025",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.3+",
      appIcon:  require("../assets/image/maratical.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.marathi.calendar&hl=en-IN",
      iosLink: "https://apps.apple.com/in/app/marathi-calendar-2025/id1448474164",
      bannerImage:  require("../assets/image/maratif.png"),
      description: "Marathi Calendar 2025",
      features: [
        {
          details:["Marathi Calendar 2025 - मराठी कॅलेंडर 2025 - Make your 2025 more organized and meaningful with the Marathi Calendar 2025 app. This easy-to-use app is perfect for anyone looking to stay connected to Marathi traditions, festivals, and important dates. Packed with detailed Panchang, auspicious timings, and daily horoscope, it’s your go-to guide for all things Marathi in 2025."]
        },
        {
          details:["मराठी कॅलेंडर 2025: सण, तिथी, आणि शुभ मुहूर्त मराठी कॅलेंडर 2025 हे तुमचं वर्ष नियोजन करण्यासाठी एक परिपूर्ण साधन आहे. या अॅपमध्ये तुम्हाला सण, व्रत, उपवास, तिथी-नक्षत्र, राशी भविष्य, आणि शुभ मुहूर्त यांची सविस्तर माहिती मिळेल. आपल्या सांस्कृतिक आणि पारंपरिक मूल्यांशी जोडून ठेवण्यासाठी, हे अ‍ॅप आहे अत्यंत उपयुक्त."]
        },
        {
          title: "2025 Marathi Calendar Key Features:",
          details: [
            " Complete Year Overview - २०२५ ची संपूर्ण माहिती Access a detailed month-by-month view of Marathi and Gregorian calendars, covering every important date for the year.",
            " Daily Panchang - दैनंदिन पंचांग Get accurate details like tithi, nakshatra, yoga, paksha, sunrise, sunset, moonrise, and moonset for each day in 2025.",
            " Festivals and Holidays - सण आणि सुट्ट्या Stay updated on all major festivals and holidays, including Ganesh Chaturthi, Diwali, Holi, Eid, and Christmas.",
            "Shubh Muhurat - शुभ मुहूर्त Find the best timings for weddings (vivah muhurat), griha pravesh, vehicle purchases, and other key events.",
            "Festivals and Holidays:",
            "Daily Rashifal - दैनंदिन राशी भविष्य Access personalized daily, weekly, and yearly horoscope updates in Marathi for all zodiac signs.",
            "Offline Access - ऑफलाइन प्रवेश Use the app without internet and access the calendar, Panchang, and festival details anytime, anywhere.",
        
           
          ],
        },
        {
          title: "2025 Calendar Additional Features - अतिरिक्त वैशिष्ट्ये",
          details: [
            "Festival Alerts - सण अलर्ट्स: Get reminders for important festivals, fasting days, and auspicious events.",
            "  Panchang Details - पंचांग तपशील: Includes daily tithi, nakshatra, and yoga to help plan religious and cultural activities.",
            " Multi-Religious Coverage - बहु-धार्मिक माहिती: Includes Hindu, Christian, and Islamic holidays for the year 2025.",
            " Custom Notifications - वैयक्तिक सूचना: Customize reminders for festivals, Muhurats, or special days important to you.",
            " Easy Navigation - सोपी रचना: Simple and user-friendly design for all ages.",
             
          ],
        },
        {
          title:"Why Choose Marathi Calendar 2025? - मराठी कॅलेंडर २०२५?",
          
          details: [
            " For Every Marathi Household: Tailored for Marathi-speaking people, covering everything from tithi to festivals.",
            "Detailed 2025 Coverage: Plan your entire year with all festivals, Panchang details, and Muhurats in one place.",
            " Accurate and Reliable: Trusted by thousands for accurate information and daily horoscope updates.",
            " Offline Use: No need for internet — access everything offline for convenience anytime.",             
          ],
        },
        {
       
          
          details: [
            " Plan Your 2025 with Marathi Calendar 2025 - २०२५ मराठी दिनदर्शिका From major festivals like Ganesh Chaturthi and Diwali to fasting days, Shubh Muhurats, and daily Rashifal, the Marathi Calendar 2025 app is your ultimate guide. Stay connected to your roots while staying organized throughout the year.",
            "Download Marathi Calendar 2025 Now! - मराठी कॅलेंडर २०२५ Make 2025 your most organized and well-planned year yet. Download Marathi Calendar 2025 now and stay connected to Marathi traditions, festivals, and daily Panchang anytime, anywhere!",           
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/Ma-1.webp"),
        require("../assets/image/Ma-2.webp"),
        require("../assets/image/Ma-3.webp"),
        require("../assets/image/Ma-4.webp"),
        require("../assets/image/Ma-5.webp"),
        require("../assets/image/Ma-6.webp"),
        require("../assets/image/Ma-7.webp"),
       
       
    ], // Dynamically importing 
    },
    {
      id: 3,
      title: "Kannada Calendar 2025",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.5+",
      appIcon:  require("../assets/image/kanadacal.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.kannada.calendar&hl=en-IN",
      iosLink: "https://apps.apple.com/in/app/kannada-calendar-2025/id1448470376",
      bannerImage:  require("../assets/image/kanadf.png"),
      description: "Kannada Calendar 2025",
      features: [
        {
          details:["Kannada Calendar 2025 Panchanga – ಕನ್ನಡ ಕ್ಯಾಲೆಂಡರ್ 2025 ಪಂಚಾಂಗ Ultimate Guide to Kannada Traditions Plan your year effortlessly with the Kannada Calendar 2025 app, designed exclusively for Kannada-speaking people around the globe. This app provides all the essential details you need for 2025, including Panchanga, festivals, Shubha Muhurat, tithi, nakshatra, and Rashi Bhavishya. Whether you’re planning a festival, checking auspicious timings, or simply staying connected to Kannada culture, this app is your perfect companion."]
        },
        {
          details:["मराठी कॅलेंडर 2025: सण, तिथी, आणि शुभ मुहूर्त मराठी कॅलेंडर 2025 हे तुमचं वर्ष नियोजन करण्यासाठी एक परिपूर्ण साधन आहे. या अॅपमध्ये तुम्हाला सण, व्रत, उपवास, तिथी-नक्षत्र, राशी भविष्य, आणि शुभ मुहूर्त यांची सविस्तर माहिती मिळेल. आपल्या सांस्कृतिक आणि पारंपरिक मूल्यांशी जोडून ठेवण्यासाठी, हे अ‍ॅप आहे अत्यंत उपयुक्त."]
        },
        {
          title: "Kannada Calendar 2025 Key Features:",
          details: [
            "Complete Kannada Panchanga View the entire Kannada Calendar 2025, covering all months and days.",
            " Get daily Panchanga details, including tithi, nakshatra, yoga, karana, sunrise, and sunset.",
            "  Easily check Amavasya, Pournami (Hunnime), and Ekadashi dates for important rituals.",     
          ],
        },
        {
   
          details: [
            "This Kannada Calendar 2025 is designed for Kannada-speaking people. It provides simple access to festivals, fasting days, Shubh Muhurat, and daily Panchang details. You can easily plan your day using information about Rahu Kalam, Yamagandam, and Gulika Kalam. This app is the best way to stay connected to Kannada culture.",
             
          ],
        },
        {
   
          details: [
            "ಈ ಕನ್ನಡ ಕ್ಯಾಲೆಂಡರ್ 2025 ಕನ್ನಡ ಭಾಷೆಯ ಜನರಿಗಾಗಿ ವಿನ್ಯಾಸಗೊಳಿಸಲಾಗಿದೆ. ಇದು ಹಬ್ಬಗಳು, ಉಪವಾಸದ ದಿನಗಳು, ಶುಭ ಮುಹೂರ್ತ, ಮತ್ತು ದೈನಂದಿನ ಪಂಚಾಂಗ ಮಾಹಿತಿ ಸರಳವಾಗಿ ಒದಗಿಸುತ್ತದೆ. ಪ್ರತಿದಿನದ ರಾಹುಕಾಲ, ಯಮಗಾಂಡ, ಗುಳಿಕ ಕಾಲದ ಮಾಹಿತಿಯನ್ನು ಬಳಸಿಕೊಂಡು ನಿಮ್ಮ ದಿನಚರಿಯನ್ನು ಸುಲಭವಾಗಿ ಯೋಜಿಸಬಹುದು. ಕನ್ನಡ ಸಂಸ್ಕೃತಿಯೊಂದಿಗೆ ಸಂಪರ್ಕ ಕಾಯ್ದುಕೊಳ್ಳಲು ಇದು ಅತ್ಯುತ್ತಮ ಬಳಕೆಯಾಗುವ ಅಪ್ಲಿಕೇಶನ್.",
             
          ],
        },
        {
   
          details: [
           "Plan Your Year with Kannada Calendar 2025 Make 2025 the best-planned year with the Kannada Calendar 2025 app. From checking daily Panchanga to finding Shubha Muhurat for big life events, this app ensures you never miss an important date or festival. It’s the perfect way to stay connected to Kannada traditions while managing your time effectively.",
             
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/K-1.webp"),
        require("../assets/image/K-2.webp"),
        require("../assets/image/K-3.webp"),
        require("../assets/image/K-4.webp"),
        require("../assets/image/K-5.webp"),
        require("../assets/image/K-6.webp"),
        require("../assets/image/K-7.webp"),
      

       
       
    ], // Dynamically importin
    
    },
  
    {
      id: 1,
      title: "Bengali Calendar 2025",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.3+",
      appIcon:  require("../assets/image/bengalical.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.bengali.calendar&hl=en-IN",
      iosLink: "https://apps.apple.com/in/app/bengali-calendar-2025/id1448462586",
      bannerImage:  require("../assets/image/Bcal.png"),
      description: "Bengali Calendar 2025",
      features: [
        {
          details:["Bengali Calendar 2025 Panjika - Stay connected to Bengali culture and traditions with the Bengali Calendar 2025, a comprehensive app for Bangla-speaking people worldwide. Featuring the Bangla 1431 - 1430 Panjika, this app provides all the essential details for 2025, including festivals, tithi, auspicious timings, and daily horoscope in Bangla."]
        },
        {
          details:["Whether you’re planning a special event, keeping track of Bengali holidays, or staying updated with daily tithi and nakshatra, this app is your ultimate guide for 2025."]
        },
        {
          title: "Key Features of Bengali Calendar 2025 - বাংলা ক্যালেন্ডার 2025",
          details: [
            " Complete Bangla Panjika 1431 – 1430 (সম্পূর্ণ বাংলা পঞ্জিকা 1431 – 1430)",
            "  Access the full Bengali Calendar 2025 including both Bengali and Gregorian months.",
            " Navigate seamlessly through the months from January to December 2025.",
            "Includes tithi, nakshatra, yoga, and paksha details for daily religious and cultural activities.",
            "Bengali Festivals and Holidays (বাঙালির উৎসব ও ছুটির দিন)",
            " Get a detailed list of Bengali festivals, Hindu holidays, and even Islamic holidays for 2025",
            "Perfect for planning cultural and religious celebrations like Poila Boishakh, Durga Puja,Saraswati Puja, and Eid.",
            "Daily Panchang and Astrology (দৈনিক পঞ্চং এবং জ্যোতিষশাস্ত্র)",
            "View accurate daily Panchang with essential details like tithi, nakshatra, and sunrise/sunset timings.",
            "Access Bangla Horoscope 2025: Get daily, weekly, and yearly horoscope updates in Bengali.",
            "Shubh Muhurat (Auspicious Timings) শুভ মুহুর্ত (শুভ সময়)",
            "Plan important events like weddings, housewarming ceremonies, and property purchases with accurate auspicious timings. ",
            "Offline Accessibility",
            " Enjoy full access to the Bengali calendar, panjika, and festival details even without an internet connection.",

        
           
          ],
        },
        {
          title: "Why Choose Bengali Calendar 2025?",
          details: [
            "Designed for Bengali Culture: Perfectly tailored for Bengali-speaking people, this app highlights cultural and traditional values.",
            " Comprehensive 2025 Coverage: Never miss an important Bengali festival, holiday, or Shubh Muhurat throughout the year.",
            " Accurate and Reliable: Offers precise Panchang, tithi, and nakshatra details for daily life.",
            " User-Friendly Design: A simple and clean interface for easy navigation, making it ideal for all ages.",
            " Offline Use: Access all features without needing an internet connection, perfect for daily convenience.",
             
          ],
        },
        {
          details: [
            " বাংলা রাশিফল এবং জ্যোতিষ শাস্ত্র বিস্তারিত বাংলা রাশিফল আপডেট, দৈনিক রাশিফল, সাপ্তাহিক অন্তর্দৃষ্টি, এবং বার্ষিক জ্যোতিষ ভবিষ্যদ্বাণী অনুসন্ধান করুন। অ্যাপটির জ্যোতিষ বৈশিষ্ট্যগুলির সাথে, ২০২৫ সালে প্রতিটি পদক্ষেপে আপনার পাশে থাকবে পরামর্শ।",             
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/L-1.webp"),
        require("../assets/image/L-2.webp"),
        require("../assets/image/L-3.webp"),
        require("../assets/image/L-4.webp"),
        require("../assets/image/L-5.webp"),
        require("../assets/image/L-6.webp"),
        require("../assets/image/L-7.webp"),
       
       
    ], // Dynamically importing 
    },
    {
      id: 2,
      title: "Hindi Calendar 2025",
      subtitle: "Local Developer Apps",
      downloads: "1K+",
      rating: "4.9+",
      appIcon:  require("../assets/image/hindi cal.png"),
      playStoreLink: "https://play.google.com/store/apps/details?id=local.hindi.calendar&hl=en-IN",
      iosLink: "https://apps.apple.com/in/app/hindi-calendar-2025/id1448467489",
      bannerImage:  require("../assets/image/hindif.png"),
      description: "Hindi Calendar 2025",
      features: [
        {
          details:["Hindi Calendar 2025 Panchang offers Tithis, festivals, Shubh Muhurats, and daily Panchang updates. Perfect for Hindi-speaking users to stay connected to Indian culture and traditions!"]
        },
        {
          details:["हिंदी कैलेंडर 2025 हिंदी पंचांग आपके लिए एक अनमोल साथी है जो आपको भारतीय संस्कृति, परंपराओं और आध्यात्मिक अनुष्ठानों से जोड़े रखता है। यह ऐप विशेष रूप से हिंदी भाषा बोलने वाले उपयोगकर्ताओं के लिए डिज़ाइन किया गया है और इसमें हर वह सुविधा शामिल है जिसकी आपको एक पारंपरिक हिंदी कैलेंडर से उम्मीद होती है। चाहे आपको त्योहारों की योजना बनानी हो, शुभ मुहूर्त जानना हो, या दैनिक पंचांग की जानकारी चाहिए हो, हिंदी कैलेंडर 2025 हिंदी पंचांग आपकी सभी आवश्यकताओं को पूरा करता है।"]
        },
        {
          title: "Hindi Calendar 2025 includes:",
          details: [
            "Complete Hindi Calendar 2025: Get a full-fledged Hindi calendar with all months, dates, Tithis, Nakshatras, and Yogas. Stay updated with important Hindi festivals like Makar Sankranti, Holi, Raksha Bandhan, and Diwali. This calendar is ideal for families relying on traditional dates for festivals, pujas, and cultural events",
            " Daily Panchang Details: Access accurate Panchang information based on Hindi traditions, including Tithi, Nakshatra, Karana, and Yoga. Plan your day, puja, or new ventures with reliable data that ensures success and prosperity.",
            " 2025 Festivals and Vrat Dates: Celebrate all major Hindi festivals with a complete list of festival and vrat dates. From Navratri and Janmashtami to Karva Chauth and Chhath Puja, this app provides all the details you need for planning and celebrations.",
            "Auspicious Muhurats: Planning a wedding, housewarming ceremony, or any important event? Get detailed Muhurat timings for all significant occasions. The app offers auspicious times based on Hindi traditions to ensure blessings and success in all your endeavors.",
            "Daily Horoscope – Rashi Fal: Begin your day with personalized Rashi Fal (daily horoscope) in Hindi. Understand your zodiac predictions and prepare for the day ahead with insights rooted in Vedic astrology.",
            "Important Dates – Ekadashi, Purnima, Amavasya: Keep track of spiritually significant days like Ekadashi, Purnima, and Amavasya. Perform your rituals, fasts, and prayers on the correct dates with the guidance of the Hindi Panchang.",
            "Offline Access: No internet? No problem! Hindi Calendar 2025 works offline, providing uninterrupted access to all its features, including Panchang, festivals, and Muhurats.",
        
           
          ],
        },
        {
          title: "2025 Calendar Additional Features - अतिरिक्त वैशिष्ट्ये",
          details: [
            "Make 2025 a memorable year with Hindi Calendar 2025! Whether you’re preparing for festivals, finding the right Muhurat for pujas, or simply staying rooted in Hindi traditions, this app is your ideal partner.",
       
          ],
        },
       
      ],
      sliderImages: [
        require("../assets/image/Ma-1.webp"),
        require("../assets/image/Ma-2.webp"),
        require("../assets/image/Ma-3.webp"),
        require("../assets/image/Ma-4.webp"),
        require("../assets/image/Ma-5.webp"),
        require("../assets/image/Ma-6.webp"),
        require("../assets/image/Ma-7.webp"),
       
       
    ], // Dynamically importing 
    },
   
 
  ];
  
  export default appsData;
  
  