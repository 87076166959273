import React from "react";
import Header from "./header";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation, useParams } from "react-router-dom";
import appsData from "./appsdata";
import img from '../assets/image/locallogo.png';
import google from '../assets/image/gooleplay.webp';
import ios from '../assets/image/ios.png';
import { Helmet, HelmetProvider } from "react-helmet-async";
import ShareButtonWithPopup from "./share";
import { useEffect } from "react";

function LocalDeveloper() {
  const location = useLocation();
  const ID = location.hash ? location.hash.substring(1) : null;
  useEffect(() => {
    // Scroll to the bottom of the page when the component mounts
    window.scrollTo({ top: 0, behavior: "smooth" });

  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: { slidesToShow: 4 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      },
    ],
  };

  const { id } = useParams();
  const appData = appsData.find((app) => app.id === parseInt(ID));

  if (!appData) {
    return <div>App not found</div>;
  }

  const AppId = {
    1: { alt: 'Bengali Calendar 2025',
       description: 'Details about Bengali Calendar',
       keywords:'Bengali Festivals,Bengali Calendar 2025,Pôñcãg Tithi,Bengali Muhurat' },

    2: { alt: 'Hindi Calendar 2025',
       description: 'Details about Hindi Calendar',
       keywords:'Tithiyan Aur Nakshatra,Shubh Muhurat,Vrat Tyohar,Hindi Calendar 2025,Hindu Panchang'
       },
    3: { alt: 'Kannada Calendar 2025',
       description: 'Details about Kannada Calendar',
       keywords:'Kannada Calendar 2025,Hindu Panchang,Kannada Panchang,Shubh Muhurat,Habbagalu Mattu Tithigalu' },
    4: { alt: 'Telugu Calendar 2025', description: 'Details about Telugu Calendar',keywords:'Telugu Calendar 2025,Shubha Muhurthalu,holidays,pandugalu,festivals' },
    5: { alt: 'Marathi Calendar 2025', description: 'Details about Marathi Calendar',keywords:'Marathi Calendar 2025,Marathi Month Names,Hindu Festivals and Dates,Wedding Muhurat Marathi,Shankaracharya Date' },
    6: { alt: 'Malayalam Calendar 2025', description: 'Details about Malayalam Calendar',keywords:'Malayalam Calendar 2025,Onam 2025,Malayalam Month Names,Malayalam Shubh Muhurtham,Wedding Muhurat Malayalam' },
    7: { alt: 'Qibla Finder', description: 'Details about Qibla Finder',keywords:'Qibla Locator,Qibla Direction,Qibla Finder App,Find Qibla Near Me,Prayer Direction' },
    8: { alt: 'Tamil Calendar 2025', description: 'Details about Tamil Calendar',keywords:'Tamil Calendar 2025,Tamil Monthly Calendar,Tamil New Year,Tamil Auspicious Dates,Tamil Calendar App' },
    9: { alt: 'Bhagavad Gita (Bengali)', description: 'Details about Bhagavad Gita (Bengali)',keywords:'Bhagavad Gita Bengali Translation,Bhagavad Gita Bengali PDF,Bhagavad Gita in Bengali Language,Bhagavad Gita Bengali App' },
    10: { alt: 'Bhagavad Gita (Punjabi)', description: 'Details about Bhagavad Gita (Punjabi)',keywords:'Bhagavad Gita Punjabi Translation,Bhagavad Gita in Punjabi Language,Punjabi Bhagavad Gita Online,Punjabi Bhagavad Gita Verses,Bhagavad Gita Punjabi Audio' },
  };

  // Fetching App Info based on the dynamic ID
  const currentApp = AppId[appData.id] || {};

  return (
    <div>
      <HelmetProvider>
      <header id="header" className="header d-flex align-items-center fixed-top">
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            <img src={img} className="sitename" alt="Local Logo" />
          </a> </div></header>
        <Helmet>
          <title>{currentApp.alt || "App Details"} </title>
          <meta name="description" content={currentApp.description || "Details about the app."} />
          <meta name="keywords" content={currentApp.keywords &&  (currentApp.keywords)} />
        </Helmet>

        <div className="bhagavad-gita">
          <div className="mobile-view">
            <div className="col-md-1 mb-1"></div>
            <div className="col-12 col-md-4 mb-4 xs-12 text-center">
              <div className="container local-dev ">
                <div className="row align-items-center">
                  {/* App Info Section */}
                  <div className="col-md-12 mb-5">
                    {/* <h3>{appData.title}</h3> */}
                    <h3 className="text-success mb-4">{appData.subtitle}</h3>
                   
                    <div className="col-md-12 mb-12 d-flex justify-content-center">
                      <div className="app-icon-wrapper">
                        <img
                          src={appData.appIcon}
                          alt="App Icon"
                          className="tamilcal"
                          style={{ boxShadow: "0 4px 8px rgba(0,0,0,0.2)",borderRadius:"20px" }}
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center ">
                      <div className="me-3">
                        <strong>{appData.downloads}</strong>
                        <span className="text-muted ms-1">Downloads</span>
                      </div>
                      <div>
                       
                        <span className="text-muted ms-1">Rated for  <strong>{appData.rating}</strong></span>
                      </div>
                    </div>
                  
                    <div className="playios-link">
                      <div className='col-md-2' ></div>
                      <div className='col-md-4 '>
                        <a
                          href={appData.playStoreLink}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={google} alt="Play Store" className="playstore" />
                        </a>
                      </div>
                      <div className='col-md-4'>
                      {appData.iosLink && (
  <a
    href={appData.iosLink}
    target="_blank"
    rel="noopener noreferrer"
  >
    <img src={ios} alt="iOS Store" className="ios" />
  </a>
)}
                      </div>
                      <div className='col-md-2' ></div>
                    </div>
                    <div className="d-flex justify-content-center">
                      {/* <button className="btn btn-success me-3">Install</button> */}
                      {/* <button className="btn btn-outline-secondary me-3">Share</button> */}
                      {/* <button className="btn btn-outline-secondary">Add to wishlist</button> */}
                      <ShareButtonWithPopup/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-12 col-md-6 mb-6 xs-12 mt-4">
              <h1>{appData.description}</h1>
              <img src={appData.bannerImage} alt="Banner" className="tamil-calendar-img" />
              </div></div><div className="container">
              {appData.features.map((feature, index) => (
                <div key={index} className="feature">
                  <h5>{feature.title}</h5>
                  <ul>
                    {feature.details.map((detail, i) => (
                      <li key={i}>{detail}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
           
        

          <div className="container">
            <Slider {...settings}>
              {appData.sliderImages.map((image, index) => (
                <div key={index}>
                  <img src={image} alt={`Slide ${index + 1}`} className="img-fluid" />
                </div>
              ))}
            </Slider>
          </div>

          <div className="text-center text-muted mt-5">
            <p>This app is available for your device</p>
          </div>

          <div className='app-footer bg-dark'>
            <footer className=" text-white pt-4 pb-2">
              <div className="container">
                <div className="row">
                  {/* Company Info */}
                  <div className="col-md-3 mb-3">
                    <a href="/" className="logo d-flex align-items-start">
                      <img src={img} className="sitenaming" alt="Local Logo" />
                    </a>
                  </div>
                  <div className="col-md-3 mb-3">
                    <h5 className="fw-bold">COMPANY INFO</h5>
                    <p>Durgi,</p>
                    <p>
                    Guntur, Andhra Pradesh - 522612.
                    </p>
                    <p>Email: dharmendrakumararigela03@gmail.com</p>
                  </div>

                  {/* Quick Links */}
                  <div className="col-md-3 mb-3">
                    <h5 className="fw-bold">QUICK LINKS</h5>
                    <ul className="list-unstyled">
                    <li>
  <a href="/#about" class="nav-link">About Us</a>
</li>                <li>
  <a href="/#portfolio" class="nav-link">Apps</a>
</li>                <li>
  <a href="/#contact" class="nav-link">Contact Us</a>
</li>
                    
                    </ul>
                  </div>

                  {/* Legal */}
                  <div className="col-md-3 mb-3">
                    <h5 className="fw-bold">LEGAL</h5>
                    <ul className="list-unstyled">
                    <a href="../../terms_privacy.htm"target='_blank' className="links">Terms & Conditions</a>
                    </ul>
                  </div>
                </div>
                {/* Footer Bottom */}
                <div className="text-center border-top pt-3">
                  <p className="mb-0">
                    &copy; Local Developer apps. All Rights Reserved.
                  </p>
                </div>
              </div>
            </footer>
          </div>

        </div>
      </HelmetProvider>
    </div>
  );
}

export default LocalDeveloper;
