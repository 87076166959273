import React from 'react';
import { Helmet } from 'react-helmet-async';

// SEO metadata mapping
const metaConfig = {
 home: {
    title: 'Best Local Mobile App Developers in India | Andhra Pradesh & Telangana | Android & iOS',
    description: 'Discover our services and offerings.',
    keywords: 'home, services, offerings, Introduction,start',
  },
  about: {
    title: 'About Us | Local developer apps',
    description: 'Learn more about our team, mission, and values.',
    keywords: 'About us, team, mission, values',
  },
  portfolio: {
    title: 'Our Portfolio | Local developer apps',
    description: 'Explore our recent projects and case studies.',
    keywords: 'portfolio, projects, case studies',
  },
  contact: {
    title: 'Contact Us | Local developer apps',
    description: 'Get in touch with us for more information.',
    keywords: 'contact, get in touch, information',
  },
  active: {
    title: 'Active Projects | Local developer apps',
    description: 'Explore active projects we are working on.',
    keywords: 'active projects, current work, updates',
  },
  techstack: {
    title: 'Our Specializations | Local developer apps',
    description: 'Discover the Technical Specialization in.',
    keywords: 'tech stack, technologies, expertise',
  },
};

// SEO component
const Seo = ({ page }) => {
    const ID =page ? page.substring(1) : null;
  const meta = metaConfig[ID] || {};
  console.log(meta)
  return (
    <Helmet>
      <title>{meta.title ? (meta.title):('Best Local Mobile App Developers in India | Andhra Pradesh & Telangana | Android & iOS')} </title>
      <meta name="description" content={meta.description &&(meta.description)} />
      <meta name="keywords" content={meta.keywords &&  (meta.keywords)} />
    </Helmet>
  );
};

export default Seo;
