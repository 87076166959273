import React, { useState } from "react";
import { SocialIcon } from "react-social-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
function ShareButtonWithPopup() {
  const [showPopup, setShowPopup] = useState(false);
  const shareUrl = "https://example.com";
  const title = "Check this out!";

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div className="position-relative">
      {/* Share Button */}
      <button
        className="btn btn-outline-secondary me-3"
        onClick={togglePopup}
      >
        <FontAwesomeIcon icon={faShareAlt} className="me-2" />
        Share
      </button>

      {/* Popup with Icons */}
      {showPopup && (
        <div
          className="position-absolute p-3 bg-white shadow rounded"
          style={{
            top: "100%",
            right: 0,
            zIndex: 1000,
            minWidth: "200px",
          }}
        >
          <h6 className="mb-3">Share via:</h6>

        

          {/* React-Social-Icons */}
          <div style={{ display: "flex", gap: "10px", marginBottom: "15px" }}>
            <SocialIcon url="https://www.whatsapp.com/" target="_blank"/>
            <SocialIcon url="https://www.slack.com/" target="_blank"/>
            <SocialIcon url="https://www.facebook.com/" target="_blank" />
            <SocialIcon url="https://www.youtube.com/" target="_blank" />
            <SocialIcon url="https://www.twitter.com/" target="_blank" />

          </div>

        
        </div>
      )}
    </div>
  );
}

export default ShareButtonWithPopup;
