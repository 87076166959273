import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import Index from "./component";
import LocalDeveloper from "./component/islamic";
import appsData from "./component/appsdata"; // Ensure this is an array of objects
import AppRouteHandler from "./component/approuter";



function App() {

    return (
        <>
            <Router>
            <AppRouteHandler />
                <Routes>
                    <Route path="/" element={<Index />} />
                    <Route
                            path="/app/id" 
                        element={<LocalDeveloper appData={{ title: "Islamic App", description: "Special App" }} />}
                    />
                </Routes>
            </Router>
        </>
    );
}

export default App;


// import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
// import Index from "./component";
// import BhagavadgitaBengali from "./component/bhagavadgitab";
// import Bhagavadgitapanjabi from "./component/bhavadgitap";
// import TamilCal from "./component/Tamilcal";
// import TeluguCalender from "./component/telugucal";
// import LocalDeveloper from "./component/islamic";
// import appsData from "./component/appsdata"; // Ensure this is an array of objects

// const DynamicApp = () => {
//   const location = useLocation();
//   console.log(location,"datta")
//   const appId = location.hash.replace("#", ""); // Extract id from the hash
//   const app = appsData.find((app) => app.id.toString() === appId);

//   return (
//     <LocalDeveloper
//       appData={app || { title: "Default Title", description: "Default Description" }}
//     />
//   );
// };

// function App() {
//   return (
//     <Router>
//       <Routes>
//         <Route path="/" element={<Index />} />
//         <Route path="/Bagavatgita_Bengali" element={<BhagavadgitaBengali />} />
//         <Route path="/Bagavatgita_Punjabi" element={<Bhagavadgitapanjabi />} />
//         <Route path="/Tamil_calender" element={<TamilCal />} />
//         <Route path="/Telugu_calender" element={<TeluguCalender />} />
//         {/* Dynamic App Route */}
//         <Route path="/app/id" element={<DynamicApp />} />
//       </Routes>
//     </Router>
//   );
// }

// export default App;




